import { Box, Flex, Heading, Input, InputGroup, InputRightAddon } from "@chakra-ui/react";
import { Icon } from "@iconify/react";
// import { getICDData } from "../../api/service/app";
import { GENDER_OPTIONS, SYMPTOMS } from "../../config/constant";
import Select from "react-select";
// import { useCreateCaseStore } from "../../store/CreateCaseStore";
// import { useCallback } from "react";
// import { debounce } from "../../utils";
import InputBox from "@components/atom/InputBox";
import { useRedStore } from "@store/store";
import useOrderAction from "@store/action-hook/useOrderAction";
import { useEffect, useState } from "react";
import { getSymptoms } from "api/service/app";

export default function MedicalReasonDropDown() {
  const currentCase = useRedStore(state => state.localOrderChanges);
  const [symptoms, setSymptoms] = useState<any>([])
  const { __updateMedicalEmergency, __updateAge, __updateGender, __updatePatientName } = useOrderAction();

  // const promiseOptions = useCallback(
  //   debounce((inputValue, callback) => {
  //     getICDData(inputValue).then(options => callback(options));
  //   }, 300),
  //   []
  // );

  useEffect(() => {
    getSymptoms().then((symptomsData) => {
      const res:any = {}
      symptomsData?.data?.medicalConditionData
      .map((item:any) => {
          if(!res[item.medicalCondition]){
              res[item.medicalCondition] = true
          }
      })
      setSymptoms(Object.keys(res))
    })
  },[])

  return (
    <Box>
      <Box p={4} m={2} my={4} bg={"white"} shadow={"sm"} borderRadius={"md"} gap={4}>
        <Box>
          <Box>
            <Flex align={"center"} mb={1}>
              <Flex justify={"center"} align={"center"} borderRadius={"full"} h={8} w={8} mr={1} color={"primary.600"}>
                <Icon icon={"solar:medical-kit-bold-duotone"} fontSize={20} />
              </Flex>
              <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                Reasons For Medical Emergency
              </Heading>
            </Flex>
          </Box>
          <Select
            isMulti
            options={symptoms.map(itm => ({ label: itm, value: itm }))}
            // loadOptions={promiseOptions}
            className="basic-single"
            classNamePrefix="select"
            value={currentCase?.requestedFor?.medicalIssue?.reason}
            isSearchable={true}
            name="color"
            placeholder="Medical reasons..."
            onChange={(e: any) => __updateMedicalEmergency(e)}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 1000 }),
              control: (baseStyles: any) => ({
                ...baseStyles,
                fontSize: 12,
                fontWeight: 500,
                minHeight: "40px",
              }),
              valueContainer: provided => ({
                ...provided,
                minHeight: "40px",
                padding: "2px 4px",
                marginTop: "0px",
                fontSize: "14px",
              }),
              input: provided => ({
                ...provided,
                margin: "0px",
              }),
              indicatorSeparator: () => ({
                display: "none",
              }),
              indicatorsContainer: provided => ({
                ...provided,
                height: "40px",
              }),
              option: (base: any) => ({
                ...base,
                zIndex: 9999,
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: "0.02em",
                textTransform: "capitalize",
                backgroundColor: "white",
                color: "black",
                // height: 20,
              }),
            }}
          />
        </Box>
        <Flex gap={4} mt={2} flexWrap={"wrap"}>
          <Box mt={4} w={32} flex={1} minW={32}>
            <Flex align={"center"} justify={"flex-start"}>
              <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} w={8} h={8} mr={1} color={"primary.600"} ml={-1.5}>
                <Icon icon={"uim:calender"} fontSize={22} />
              </Flex>
              <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                Age in Years
              </Heading>
            </Flex>
            <InputGroup size="md">
              <Input placeholder="Age" type="tel" value={currentCase?.requestedFor?.age || 0} borderLeftRadius={"base"} maxLength={3} onChange={e => __updateAge(parseInt(e.target.value))} />
              <InputRightAddon children="Years" fontSize={"xs"} fontWeight={"semibold"} borderRightRadius={"base"} />
            </InputGroup>
          </Box>
          <Box mt={4} flex={1}>
            <Flex align={"center"} justify={"flex-start"}>
              <Flex justify={"center"} align={"center"} borderRadius={"full"} py={1} h={8} w={8} mr={1} ml={-1.5} color={"primary.600"}>
                <Icon icon={"ph:gender-intersex-duotone"} fontSize={22} />
              </Flex>
              <Heading fontWeight={"normal"} fontSize={"sm"} textTransform={"capitalize"}>
                Gender
              </Heading>
            </Flex>

            <Select
              defaultInputValue=""
              options={GENDER_OPTIONS}
              placeholder={"Gender"}
              value={currentCase?.requestedFor?.gender || ""}
              onChange={(e: any) => __updateGender(e)}
              isClearable={false}
              styles={{
                control: (baseStyles: any) => ({
                  ...baseStyles,
                  fontSize: 14,
                  fontWeight: 500,
                  minWidth: 150,
                  maxWidth: 170,
                  minHeight: "40px",
                  height: "40px",
                }),
                valueContainer: (provided: any) => ({
                  ...provided,
                  height: "40px",
                  padding: "0 6px",
                }),
                input: (provided: any) => ({
                  ...provided,
                  margin: "0px",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                indicatorsContainer: (provided: any) => ({
                  ...provided,
                  height: "40px",
                }),
                option: (base: any) => ({
                  ...base,
                  zIndex: 9998,
                  fontSize: 14,
                  fontWeight: 500,
                  letterSpacing: "0.02em",
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  color: "black",
                }),
              }}
            />
          </Box>
        </Flex>
        <InputBox size={"md"} defaultValue={currentCase?.requestedFor?.name} label="Patient Name" onChange={(e: any) => __updatePatientName(e.target.value)} />
      </Box>
    </Box>
  );
}
